<template>
    <div class="block">
        <el-timeline>
            <el-timeline-item :timestamp="item.dateTime" placement="top" v-for="(item, index) in propValue" :key="index">
              <el-card>
                <h4>{{item.title}}</h4>
                <p>{{item.content}}</p>
              </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>

</template>

<script>
export default {
    props: {
        propValue: {
            type: Array,
            require: true,
        },
    },
}
</script>

<style lang="scss" scoped>
</style>